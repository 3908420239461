import React, { useEffect } from 'react';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import SEO from '../components/App/SEO';
import { graphql } from 'gatsby';

const AndroidCreditsPage = ({ data }) => {
  const iframeSrc =
    typeof window !== "undefined"
      ? "https://ab.bluemail.me" + "?x-frames-allow-from=" + window.location.origin
      : "";

  useEffect(() => {
    let interval;

    const postMessageToIframe = () => {
      const iframe = document.getElementById("your-iframe-id");
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ action: "checkLogin" }, "*");
      }
    };

    const handleMessage = (event) => {
      const iframe = document.getElementById("your-iframe-id");
      if (iframe && event.source === iframe.contentWindow) {
        try {
          const parsedData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
          if (parsedData.newSrc) {
            iframe.src =
              parsedData.newSrc + 
              "?x-frames-allow-from=" + 
              window.location.origin + 
              "&t=" + new Date().getTime();
            window.removeEventListener("message", handleMessage);
          }
        } catch (error) {
          console.error("Failed to parse message data:", error);
        }
      }
    };

    const handleCheckLogin = (event) => {
      try {
        if (event.data?.isLoggedIn === true) {
          const iframe = document.getElementById("your-iframe-id");
          iframe.src = iframeSrc + "&t=" + new Date().getTime();
          clearInterval(interval);
        }
      } catch (error) {
        console.error("Failed to handle check login:", error);
      }
    };

    if (typeof window !== "undefined") {
      interval = setInterval(postMessageToIframe, 1000);
      window.addEventListener("message", handleMessage);
      window.addEventListener("message", handleCheckLogin);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("message", handleMessage);
        window.removeEventListener("message", handleCheckLogin);
        clearInterval(interval);
      }
    };
  }, [iframeSrc]);

  return (
    <Layout env={data.site.siteMetadata.env}>
      <Navbar />
      <SEO 
        postTitle='Android Credits | BlueMail App' 
        postDescription='Everything new about Blue Mail - Redefining Your Email'
        postImage='/img/OG/og_image-Android.png'
        postURL='p1'
        postSEO
      />
      <div className="android-credits-area container pt-120 pb-100">
        <iframe id="your-iframe-id" src={iframeSrc} width="800px" height="600px" title="Android Credits"></iframe>
      </div>
      <Footer />
    </Layout>
  );
};

export default AndroidCreditsPage;

export const query = graphql`
  query AndroidCreditsPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`;
